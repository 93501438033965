import { computed, ref } from '@vue/composition-api/dist/vue-composition-api'
import { comparableString } from '../../utils/utils'

import moment from 'moment'
import i18n from '../../libs/i18n'
import { capitalize } from '../../utils/filter'

export const useSearchFilter = () => {
  // ------------------------------------------------
  // Data
  // ------------------------------------------------
  const selectedView = ref('grid')
  const query = ref('')
  const pageNumber = ref(1)
  const perPage = ref(50)
  const itemViewOptions = ref([
    { icon: 'th-large', value: 'grid' },
    { icon: 'list', value: 'list' },
  ])
  const localFilters = ref([])
  const selectedFilters = ref([])
  const sortBy = ref(null)
  const sortByIsDesc = ref(false)
  const mapView = ref(null)

  // ------------------------------------------------
  // Computed
  // ------------------------------------------------

  // ------------------------------------------------
  // Watch
  // ------------------------------------------------

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------
  const paginateArray = (array, perPage, page) => {
    let workedArray = JSON.parse(JSON.stringify(array))
    return workedArray.slice((page - 1) * perPage, page * perPage)
  }

  const getLast3months = (columnObject) => {
    let now = moment()
    let nowSub1 = moment().subtract(1, 'month')
    let nowSub2 = moment().subtract(2, 'month')

    return [
      {
        text: capitalize(now.format('MMMM')),
        column: columnObject,
        key: '_date_month_filter',
        value: now.format('YYYY-MM')
      },
      {
        text: capitalize(nowSub1.format('MMMM')),
        column: columnObject,
        key: '_date_month_filter',
        value: nowSub1.format('YYYY-MM')
      },
      {
        text: capitalize(nowSub2.format('MMMM')),
        column: columnObject,
        key: '_date_month_filter',
        value: nowSub2.format('YYYY-MM')
      },
    ]
  }

  const getLasYears = (columnObject, items) => {
    let years = [];

    items.forEach(item=> {
      let year = new Date(item[columnObject.key]).getFullYear()

      if (!years.some(y=>y.text == year)) {
        years.push({
          text: year,
          column: columnObject,
          key: '_date_year_filter',
          value: year
        })
      }
    })

    return years
  }

  const selectFilter = (newFilter) => {
    // console.log(selectedFilters.value)
    // console.log(newFilter)
    if (newFilter.key == '_date_month_filter') {
      let newFilterCloned = JSON.parse(JSON.stringify(newFilter))
      newFilterCloned.text = newFilter.column.display + ' : ' + newFilter.text

      if (!selectedFilters.value.some(f => f.key == newFilter.key)) {
        selectedFilters.value.push(newFilterCloned)
      }

    } else if (newFilter.key == '_date_custom_filter') {
      let newFilterCloned = JSON.parse(JSON.stringify(newFilter))
      newFilterCloned.text = newFilter.column.display + ' : du ' + moment(newFilter.value.start).format('L') + ' au ' + moment(newFilter.value.end).format('L')

      if (!selectedFilters.value.some(f => f.key == newFilter.key)) {
        selectedFilters.value.push(newFilterCloned)
      }
    } else if (!('children' in newFilter)) {
      if (!selectedFilters.value.some(f => f.key == newFilter.key)) {
        selectedFilters.value.push(newFilter)
      }
    }
  }

  const isLikeQuery = (item) => {
    if (item == null || typeof item == 'boolean') {
      return false
    }
    let localQuery = comparableString(query.value)

    if (/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:Z|(\+|-)([\d|:]*))?$/.test(item)) {
      if (
        comparableString(moment(item).format('LL')).includes(localQuery) ||
        comparableString(moment(item).format('L')).includes(localQuery)
      ) {
        return true
      }

    } else {
      item = comparableString(item.toString())
    }

    if (item.includes(localQuery)) {
      return true
    } else {
      return false
    }
  }

  const toogleItem = (item, isOut) => {
    if (item.children) {

      if (!isOut) {
        item.showSubMenu = true
      } else {
        if (!item.showSubMenu) {
          item.showSubMenu = true
        } else {
          item.showSubMenu = false
        }
      }
    }
  }

  const rowClass = (item) => {
    if (item._isDisabled == true) return 'table-secondary'
  }

  const tableSortCompare = (ctx) => {
    sortBy.value = ctx.sortBy
    sortByIsDesc.value = ctx.sortDesc
  }

  // ------------------------------------------------
  // Mounted
  // ------------------------------------------------

  // ------------------------------------------------
  // Setup
  // ------------------------------------------------

  return {
    // Components

    // Data
    selectedView,
    query,
    pageNumber,
    perPage,
    itemViewOptions,
    localFilters,
    selectedFilters,
    sortBy,
    sortByIsDesc,
    mapView,


    // Computed

    // Methods
    paginateArray,
    getLast3months,
    getLasYears,
    selectFilter,
    isLikeQuery,
    toogleItem,
    rowClass,
    tableSortCompare,

  }
}