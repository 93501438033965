var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('search-filter',{attrs:{"items":_vm.contacts,"isActionColumnEnabled":true,"itemView":"list","sortOptions":_vm.sortOptions,"columns":_vm.columns,"addMapView":true},on:{"addItem":_vm.addContact},scopedSlots:_vm._u([{key:"gridView",fn:function(ref){
var item = ref.item;
return [_c('card-contact',{attrs:{"contact":item},on:{"click":function($event){return _vm.selectContact(item)},"deleteContact":function($event){return _vm.deleteContactAlert(item)}}})]}},{key:"listView_cell_contact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item._display)+" ")]}},{key:"listView_cell_email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:'+item.email}},[_vm._v(_vm._s(item.email))])]}},{key:"listView_cell_phone",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'tel:'+item.phone}},[_vm._v(_vm._s(item.phone))])]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('Edit'))),expression:"capitalize($t('Edit'))",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},on:{"click":function($event){return _vm.selectContact(item)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('delete'))),expression:"capitalize($t('delete'))",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm","disabled":!item._isDeletable},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteContactAlert(item)}}})],1)]}}])}),_c('modal-contact',{attrs:{"contact":_vm.currentContact,"title":_vm.contactModalTitle,"isContactEdit":_vm.isContactEdit,"isOpen":_vm.contactModalShow},on:{"update:contact":function($event){_vm.currentContact=$event},"update:isOpen":function($event){_vm.contactModalShow=$event},"update:is-open":function($event){_vm.contactModalShow=$event},"submitValidatedContact":_vm.submitValidatedContactLocal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }