import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useContacts } from './useContacts'
import { capitalize } from '@/utils/filter'

import SearchFilter from '../../components/searchFilterV2/SearchFilter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAPI from '@/utils/useAPI'
import CardContact from '../../components/card/Contact'
import ModalContact from '@/components/prompt/Contact'

export const mixinContacts = {
  components: {
    SearchFilter,
    CardContact,
    ModalContact,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const contactModalShow = ref(false)
    const contactModalTitle = ref(i18n.t('NewContact'))
    const currentContact = ref({})
    const isContactEdit = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(contactModalShow, () => {
      if (contactModalShow.value == false) {
        resetContact()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchUsers, fetchCompanies } = useAPI()

    const {
      submitValidatedContact,
      removeContact
    } = useContacts()

    const addContact = () => {
      contactModalShow.value = true
    }

    const submitValidatedContactLocal = () => {
      submitValidatedContact(currentContact.value)
        .then(response => {
          contactModalShow.value = false
        })
    }

    const resetContact = () => {
      currentContact.value = JSON.parse(JSON.stringify({
        _isCompany: true,
        companyEntity: store.getters['company/getEmptyCompany'],
        userEntity: store.getters['user/getEmptyUser']
      }))

      contactModalTitle.value = i18n.t('NewContact')
      isContactEdit.value = false
    }

    const selectContact = (contact) => {
      contactModalTitle.value = i18n.t('EditContact')
      isContactEdit.value = true

      currentContact.value = {
        _isCompany: isCompany(contact),
        companyEntity: isCompany(contact) ? contact : store.getters['company/getEmptyCompany'],
        userEntity: !isCompany(contact) ? contact : store.getters['company/getEmptyCompany']
      }


      if (isCompany(contact)) {
        fetchCompanies(contact._vSelectId).then((company) => {
          currentContact.value = {
            _isCompany: true,
            companyEntity: company,
            userEntity: store.getters['company/getEmptyCompany']
          }

          contactModalShow.value = true
        })
      } else {
        fetchUsers(contact._vSelectId).then((user) => {
          currentContact.value = {
            _isCompany: false,
            companyEntity: store.getters['user/getEmptyUser'],
            userEntity: user
          }

          contactModalShow.value = true
        })
      }

    }

    const isCompany = (contact) => {
      return (contact._filters.some(flt => flt.key == '_filter_isCompany' && flt.value == true))
    }

    const removeContactLocal = (contact) => {
      // console.log(contact)
      let currentContact = JSON.parse(JSON.stringify(contact))
      currentContact.id = contact._vSelectId
      removeContact(currentContact)
        .then(response => {
          // if (getRoute().name == 'Contact view') {
          //   router.push({ name: 'Contacts' })
          // } else if (getRoute().name == 'Workflow view') {
          //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
          //   workflow.contacts = workflow.contacts.filter(ii => ii.id != contact.id)
          // }

        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchUsers().then(() => {
      // console.log('users ok')
    })
    fetchCompanies().then(() => {
      // console.log('companies ok')
    })

    resetContact()

    return {
      // Components
      capitalize,

      // Data
      contactModalShow,
      contactModalTitle,
      currentContact,
      isContactEdit,

      // Computed

      // Methods
      addContact,
      submitValidatedContactLocal,
      selectContact,
      removeContactLocal
    }
  },
  data () {
    return {}
  },
  methods: {
    deleteContactAlert (contact) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theContact') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeContactLocal(contact)
          }
        })
    }
  },
}

export const _ = null
